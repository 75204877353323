@import url("https://rsms.me/inter/inter-ui.css");
@import "./Theme.scss";

html {
  height: 100%;
  background-color: var(--bg-2);
}

body {
  margin: 0;
  font-family: "Inter UI", sans-serif !important;
}

h1 {
  font-size: 2.2em;
  font-weight: 600;
}

h2 {
  font-weight: 600;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-bar {
  opacity: 0;
}

.content {
  background-color: var(--bg-2);
}