.segmentContainer {
  display: flex;
  //   flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
}

.segmentRow {
  flex-direction: row;
  justify-content: space-between;
}

.smallScreen {
  display: none;
}

.cardHeaderAction {
  margin-top: 0;
  margin-right: 0;
}

.contentGrid {
  margin: 30px;
}

.placeholder {
  opacity: 0.5;
}

.datePicker {
  margin: 20px;
  width: 100%;
}

.textField {
  width: 100%;
}

.fileDrop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.card {
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  align-items: flex-start;
  justify-content: flex-start;
}

.buttonStyle {
  float: right;
  animation: moveAcross 1s 1;
}

@keyframes moveAcross {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 1550px) {
  .largeScreen {
    display: none;
  }

  .smallScreen {
    display: inherit;
    font-size: 16;
  }
}

pre {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
 }