.dropzone {
  height: 100%;
  max-height: 300px;
  width: 100%;
  background-color: #fff;
  border: 2px dashed rgb(187, 186, 186);
  border-radius: 4px;
  padding: 32px;
  overflow: scroll;
}

.error {
  border: 2px dashed rgb(255, 0, 0);
}

.innerZone {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  font-size: 16px;
  width: 100%;
  height: 100%;
}

.dropzone.highlight {
  background-color: rgb(220, 220, 220);
  animation: pulse 1s 1;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}

.icon {
  opacity: 0.3;
  height: 64px;
  width: auto;
  margin: 5px;
}

.fileInput {
  display: none;
}

.textStyle {
  word-wrap: normal;
  margin: 5px;
}

.item {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.gridContent {
  width: 100%;
}
