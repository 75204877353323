.headerBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: 700 !important;
  background-color: var(--bg);
  color: var(--font);
  height: 89px;

  position: fixed;
  z-index: 1200;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 60px;
  margin-left: 270px;
}

.leftContainer {
  font-size: 26px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 2 0 auto;
}

.minifiedActions {
  display: none;
}

@media only screen and (max-width: 960px) {
  .minifiedActions {
    display: inherit;
  }

  .headerBlock {
    margin-left: 0;
  }

  .rightContainer {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .leftContainer {
    font-size: 18px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 2 0 auto;
  }

  .headerBlock {
    height: 59px;
  }

  .rightContainer {
    width: 120px;
    text-align: right;
  }
}
