:root {
  --bg: #FFF;
  --bg-1: #EBEBEB;
  --bg-2: #F8F8F8;
  --primary: #c31d1d;
  --accent: #c41212;
  --accent-2: rgb(160, 19, 19);
  --secondary: #cf2727;
  --font: #000;
  --secondary-font: rgba(0, 0, 0, 0.40);
  --border: 1px solid rgba(0, 0, 0, 0.12);
  --filter: none;
  height: 100%;
}

[data-theme="dark"] {
  --bg: #212121;
  --bg-1: #303030;
  --bg-2: #303030;
  --primary: #273A59;
  --accent: #1C3452;
  --accent-2: #2A486E;
  --secondary: #1F3B87;
  --font: #FFF;
  --secondary-font: rgba(255, 255, 255, 0.40);
  --border: 1px solid rgba(255, 255, 255, 0.12);
  --filter: brightness(0) invert(1);
}

// :root {
//   --bg: #FFF;
//   --bg-1: #EBEBEB;
//   --bg-2: #F8F8F8;
//   --primary: #c31d1d;
//   --accent: #c41212;
//   --accent-2: rgb(160, 19, 19);
//   --secondary: #cf2727;
//   --font: #000;
//   --secondary-font: rgba(0, 0, 0, 0.40);
//   --border: 1px solid rgba(0, 0, 0, 0.12);
//   --filter: none;
//   height: 100%;
// }

// [data-theme="dark"] {
//   --bg: #212121;
//   --bg-1: #303030;
//   --bg-2: #303030;
//   --primary: #273A59;
//   --accent: #1C3452;
//   --accent-2: #2A486E;
//   --secondary: #1F3B87;
//   --font: #FFF;
//   --secondary-font: rgba(255, 255, 255, 0.40);
//   --border: 1px solid rgba(255, 255, 255, 0.12);
//   --filter: brightness(0) invert(1);
// }
