
.contentGrid {
  margin: 30px;
}

.cardTitle {
  font-size: 23px;
  font-weight: 500;
}

.cardDescription {
  margin-top: 10px;
}

.card {
  display: flex;
  padding: 30px;
  align-items: center;
}

.cardContainer {
  cursor: pointer;
  transform: scale(1);
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.cardContainer:hover {
  transform: scale(0.99);
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.cardImage {
  min-width: 50px;
  margin: 0 20px;
}