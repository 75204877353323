.content {
  margin: 0;
  padding: 0;
  padding-top: 48px;
  height: 100%;
}

.hidden {
  display: none;
}
