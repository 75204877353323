.table {
  width: 100%;
  background-color: var(--bg);
}

.tablePagination {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--bg);
  border: var(--border);
}

.rowName {
  color: #4c73ff;
  font-weight: 500;
  cursor: pointer;
}

.inset {
  margin-left: 20px;
}

.tableRow {
  padding-left: 30px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 500px) {
  .table{
    width: 95vw;
  }
}
