.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  opacity: 0.2;
  color: var(--font);
}

.text {
    font-size: 2em;
    margin-top: 1em;
}

.image {
    height: 5em;
    width: 5em;
}