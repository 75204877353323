@import "../../Theme.scss";

.content {
  background-color: orange;
}

.container {
  height: 4rem;
  background-color: #323951;
}

.subNav {
  height: 100px;
  width: 100px;
  background-color: black;
}

.appBar {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  justify-content: space-between;
  position: fixed;
  height: 50px;
  border-bottom: var(--border);
  left: 0;
  right: 0;
  z-index: 1201;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  background-image: linear-gradient(-53deg, #e33e3d 0%, #c31d1d 100%);
}

.drawerItems {
  background-color: var(--bg-1);
  height: 100%;
  margin-top: 50px;
  color: var(--font);
}

.loading {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.logo {
  height: 25px;
  filter: brightness(0) invert(1);
}

.appName {
  font-size: 18px;
  font-weight: 800;
  margin-left: 0.4em;
  margin-right: 1em;
  color: #fff;
}

.hamburger {
  height: 20px;
  margin-right: 20px;
  align-self: center;
  cursor: pointer;
  filter: invert(1);
  display: none;
}

.breadcrumbContainer {
  color: #fff;
  display: flex;
}

@media only screen and (max-width: 960px) {
  .hamburger {
    display: inherit;
    filter: invert(1) !important;
  }

  .breadcrumbContainer {
    display: none;
  }

  .drawerItems {
    margin-top: 0px;
  }
}

.hamburger:hover {
  opacity: 0.7;
  filter: invert(1);
}

.appNameContainer {
  display: flex;
  cursor: pointer;
  margin-right: 30px;
  align-items: center;
}

.appBarLeft {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-grow: 1;
}

.appBarRight {
  position: relative;
  height: 100%;
}

.drawerPaper {
  background-color: orange;
}

.breadcrumb {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  text-decoration: none !important;
  opacity: 0.8;
}

.breadcrumbLink {
  text-decoration: none;
  font-weight: 600;
  color: #fff;
}

.divider {
  font-size: 14px;
  margin-left: 8px;
  margin-right: 8px;
}

.environmentChip {
  color: var(--bg) !important;
  border-color: var(--bg) !important;
  margin-left: 0.4em;
  margin-right: 0.4em;
}

// Very small screen mode, intended to only affect elements viewed in mobile such as Rosters.
@media only screen and (max-width: 500px) {
  .appName {
    display: none;
  }
}