.buttonLabelSm {
  display: none;
}

.popper {
    z-index: 100;
}


@media only screen and (max-width: 920px) {
  .buttonLabelLg {
    display: none;
  }

  .buttonLabelSm {
    display: inherit;
    font-size: 16px;
  }
}
